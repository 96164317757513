import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of ,throwError} from 'rxjs';
import { map, filter, debounceTime, catchError, tap } from 'rxjs/operators';
import{AppConfigService} from '../app-config.service'
import { IBrandContent} from '../common/ibrand-content';
import { AppError } from '../common/class/app-error';
import  *  as  connections  from  '../../assets/connections.json';
@Injectable({
  providedIn: 'root'
})
export class RedactReplyServiceService {
  baseUrl: string;
  redactTextUrl = 'api/v1/Redact/TextView/{shortURL}';
  postredactTextUrl = 'api/v1/Redact/TextView';
  postUserVerificationUrl='api/v1/UserVerification';
  postUserInformationUrl='api/v1/UserInformation';
  DITextViewDurationurl = 'api/v1/DI/TextViewDuration';
  VerifyEmail= 'api/v1/DI/VerifyEmail';
  VerifyPassword= 'api/v1/DI/VerifyPassword';
  UITextUrl='api/v1/DI/TextView/{shortURL}';
  postUITextUrl='api/v1/DI/TextView';
  redactTextViewDurationurl = 'api/v1/Redact/TextViewDuration';
  redactSenderTextUrl = 'api/v1/Redact/SenderTextView/{shortURL}';
  countryDialCodeUrl = 'api/v1/CountryDialCodeList';
  countryDialCodeForShortUrl = 'api/v1/CountryDialCodeForSenderAddress';
    constructor(private http: HttpClient,private config: AppConfigService) {
  this.baseUrl = this.config.baseApiUrl;
  // let langb:any =(connections as any).default;
  // this.baseUrl =langb['baseUrl'];
   }
   getBrandDetails(): IBrandContent {
    if (localStorage.getItem('brand') !== undefined && localStorage.getItem('brand') !== null) {
      return JSON.parse(localStorage.getItem('brand'));
    } else {
      return null;
    }
  }
  apiRedactTextViewDuration(LogId: string) {
   // const url = this.redactTextViewDurationurl;
    const url = `${this.baseUrl}`+ this.redactTextViewDurationurl;
    const resource = {Id: LogId, ViewDurationInSeconds: '60' };
    return this.http.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      catchError(this.handleError)
    );
  }
  apiDITextViewDuration(LogId: string) {
    // const url = this.redactTextViewDurationurl;
     const url = `${this.baseUrl}`+ this.DITextViewDurationurl;
     const resource = {Id: LogId, ViewDurationInSeconds: '60' };
     return this.http.post(url, JSON.stringify(resource), {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'
       })
     }).pipe(
       catchError(this.handleError)
     );
   }
   postapiVerifyEmail(Id : string,EmailAddress : string) {
    // const url = this.redactTextViewDurationurl;
     const url = `${this.baseUrl}`+ this.VerifyEmail;
     const resource = {Id: Id, EmailAddress: EmailAddress };
     return this.http.post(url, JSON.stringify(resource), {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'
       })
     }).pipe(
       catchError(this.handleError)
     );
   }
   postapiVerifyPassword(Id : string,Password  : string) {
    // const url = this.redactTextViewDurationurl;
     const url = `${this.baseUrl}`+ this.VerifyPassword;
     const resource = {Id: Id, Password: Password  };
     return this.http.post(url, JSON.stringify(resource), {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'
       })
     }).pipe(
       catchError(this.handleError)
     );
   }
   postRedactTextView(shortURL : string) {
    // const url = this.redactTextViewDurationurl;
     const url = `${this.baseUrl}`+ this.postredactTextUrl;
    
     const resource = {Id : shortURL, APIKey :  this.config.baseApiKey  };
     return this.http.post(url, JSON.stringify(resource), {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'
       })
     }).pipe(
       catchError(this.handleError)
     );
   }
   
   postUserInformation(shortURL : string,type:string) {
    // const url = this.redactTextViewDurationurl;
     const url = `${this.baseUrl}`+ this.postUserInformationUrl;    
     const resource = {ShortUrl : shortURL, Type:type};
     return this.http.post(url, JSON.stringify(resource), {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'
       })
     }).pipe(
       catchError(this.handleError)
     );
   }
   
   postUserVerification(shortURL : string,emailId:string, phoneNumber:string,countryDialCode:string,type:string) {
    // const url = this.redactTextViewDurationurl;
     const url = `${this.baseUrl}`+ this.postUserVerificationUrl;    
     const resource = {ShortUrl : shortURL, EmailAddress :  emailId ,PhoneNumber: phoneNumber, CountryDialCode: countryDialCode, Type:type};
     return this.http.post(url, JSON.stringify(resource), {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'
       })
     }).pipe(
       catchError(this.handleError)
     );
   }
   
   
   postDITextView(shortURL : string,password:string) {
       // const url = this.redactTextViewDurationurl;
     const url = `${this.baseUrl}`+ this.postUITextUrl;
    //  const resource = {Id : shortURL, APIKey :  this.config.baseApiKey  };
    const resource = {Id : shortURL, APIKey :  this.config.baseApiKey,  Verify:password};
     return this.http.post(url, JSON.stringify(resource), {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'
       })
     }).pipe(
       catchError(this.handleError)
     );
   }
  
   postCountryDialCodeForSenderAddress(shortURL:string): Observable<any>{
    const url = `${this.baseUrl}`+this.countryDialCodeForShortUrl;
    const resource = {ShortUrl : shortURL};
    return this.http.post(url, JSON.stringify(resource),{
      headers: new HttpHeaders({
        'Content-Type' : 'application/json'
      })
    }).pipe(
      catchError(this.handleError)
    )
  }

  getRedactTextView(shortURL: string): Observable<any> {
    const url = this.redactTextUrl.replace('{shortURL}', shortURL);
   
    return this.apiGetById(url);
  }
  getDITextView(shortURL: string): Observable<any> {
    const url = this.UITextUrl.replace('{shortURL}', shortURL);
   
    return this.apiGetById(url);
  }
  getRedactSenderTextView(shortURL: string): Observable<any> {
    const url = this.redactSenderTextUrl.replace('{shortURL}', shortURL);
   
    return this.apiGetById(url);
  }
  getCountryDialCodeList(): Observable<any>{
    const url = `${this.baseUrl}`+ this.countryDialCodeUrl;
    return this.http.get(url)
    .pipe(
      catchError(this.handleError)
    );
  }
  
  apiGetById(_url: string) {
     const url = `${this.baseUrl}${_url}`;
    return this.http.get(url)
    .pipe(
      catchError(this.handleError)  
    );  
 
  }
 private handleError(err: HttpErrorResponse ): Observable<AppError> {
   if(err != undefined){
   const appError = new AppError(err);
   const errorStatus = err.status;
   const errorStatusText = err.statusText;
   const error: any = err.error;
   let errorMsg = '';

   if (error.Message !== undefined && Array.isArray(error.Message)) {
       errorMsg = err.error.Message[0].Message;
   } else {
     if (error.error_description) {
       errorMsg = error.error_description;
     } else {
       if (error.Message) {
         errorMsg = error.Message;
       } else {
         errorMsg = errorStatusText;
       }
     }
   }

   const retMsg = `Error (${errorStatus}): ${errorMsg}`;
   appError.Message = retMsg;

   return of(appError);
 }
 }


}

