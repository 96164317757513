import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of ,throwError} from 'rxjs';
import { map, filter, debounceTime, catchError, tap, switchMap } from 'rxjs/operators';
// import{AppConfigService} from '../app-config.service';
// import { AppError } from '../common/class/app-error';
import  *  as  connections  from  '../../assets/connections.json';
import { DestinationDetails } from 'src/app/class/DestinationDetails';
import { AutoLockRequestModel } from 'src/app/class/AutoLockRequestModel';
import { AppConfigService } from 'src/app/app-config.service';
import { AppError } from 'src/app/common/class/app-error';
import { AutoLockApiResponse, ResultContent } from 'src/app/class/AutoLockApiResponse';
import { SendAccessControlNoticeRequestModel } from 'src/app/class/SendAccessControlNoticeRequestModel';


@Injectable({
    providedIn: 'root'
  })
export class AI_AutoLock_Access_Check {
    ActAPIbaseUrl: string;
    baseUrl: string;
    destinationDetailsUrl = 'api/LargemailDownload/DestinationDetails/{shortURL}';
    accessControlUrl = 'api/v1/AccessControl';
    sendAccessControlNoticeUrl='api/internalnotification/SendAccessControlNotice/';
    ServerVariablesUrl = 'api/v1/ServerVariables';

    constructor(private http: HttpClient,private config: AppConfigService) {
        this.baseUrl = this.config.baseApiUrl;
        this.ActAPIbaseUrl = this.config.actAPIUrl;
    }   

    getDestinationDetails(shortUrl: string): Observable<DestinationDetails> {
        const url = `${this.baseUrl}${this.destinationDetailsUrl.replace('{shortURL}', shortUrl)}`;
        return this.http.get<DestinationDetails>(url).pipe(
            catchError(err => {
                console.error('Error during getDestinationDetails', err);
                return of(null);
              })
        );
    }

    // Fetch Server Variables
    getServerVariables(): Observable<{ IPAddress: string, UserAgent: string, Referer: string, BrowserLanguage: string }> {
      const url = `${this.baseUrl}${this.ServerVariablesUrl}`;
      return this.http.get<{ Status: string, StatusCode: number, StatusText: string, Message: any[], ResultContent: { IPAddress: string, UserAgent: string, Referer: string, BrowserLanguage: string } }>(url).pipe(
        map(response => response.ResultContent), 
        catchError(err => {
          console.error('Error during getServerVariables', err);
          return of(null);
        })
      );
    }

    getautoLockAccess(autoLockRequestModel: AutoLockRequestModel): Observable<AutoLockApiResponse> {
        const url = `${this.ActAPIbaseUrl}${this.accessControlUrl}`;
        return this.http.post<AutoLockApiResponse>(url, JSON.stringify(autoLockRequestModel), {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }).pipe(
            catchError(err => {
                console.error('Error during getautoLockAccess', err);
                return of(null);
              })
        );
    }   


    sendAccessControlNotice(sendAccessControlNoticeRequestModel: SendAccessControlNoticeRequestModel): Observable<any> {
        const url = `${this.baseUrl}${this.sendAccessControlNoticeUrl}`;
        return this.http.post(url, JSON.stringify(sendAccessControlNoticeRequestModel), {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }).pipe(
          catchError(this.handleError)
        );
      }

    private handleError(err: HttpErrorResponse ): Observable<AppError> {
        if(err != undefined){
        const appError = new AppError(err);
        const errorStatus = err.status;
        const errorStatusText = err.statusText;
        const error: any = err.error;
        let errorMsg = '';
     
        if (error.Message !== undefined && Array.isArray(error.Message)) {
            errorMsg = err.error.Message[0].Message;
        } else {
          if (error.error_description) {
            errorMsg = error.error_description;
          } else {
            if (error.Message) {
              errorMsg = error.Message;
            } else {
              errorMsg = errorStatusText;
            }
          }
        }
     
        const retMsg = `Error (${errorStatus}): ${errorMsg}`;
        appError.Message = retMsg;
     
        return of(appError);
      }

    }

}

