import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const minutes: number = Math.floor(value / 60);
    if(minutes>=0 )
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
else
return ('0');
  }

}
