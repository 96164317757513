import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-redact-new',
  templateUrl: './redact-new.component.html',
  styleUrls: ['./redact-new.component.scss']
})
export class RedactNewComponent implements OnInit {
  FooterHtml: SafeHtml;
  constructor() { }

  ngOnInit() {
    this.FooterHtml ="<p>RPost Copyright 2000 - 2021 All Rights Reserved.\r\n        <a href=\"https://www.rpost.com/legal-notice\" title=\"Legal Notice\" target=\"_blank\" >Legal Notice</a>\r\n        - View general terms &amp; conditions, service level agreements, privacy and legal notices. RPost does not provide legal advice.</p>\r\n    <p>RPost technology is <a href=\"https://www.rpost.com/about-rpost/intellectual-property/patents\" title=\"RPost Patents\" target=\"_blank\">patented</a>\r\n        with worldwide coverage. REGISTERED EMAIL®, RPOST®, RMAIL®, (R)®, (R)EGISTERED EMAIL®, LEGAL PROOF®, ESIGNOFF®, SIDENOTE®, SECURMAIL(TM) are among RPost’s\r\n        <a href=\"https://www.rpost.com/about-rpost/intellectual-property/trademarks\" target=\"_blank\">registered trademarks</a>\r\n        in the United States and registered and unregistered trademarks in countries worldwide.</p>";
  
  }

    

}
