import { Component, OnInit } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { switchMap, catchError } from 'rxjs/operators';
import { interval } from 'rxjs';
import { IBrand,IBrandHF} from '../common/ibrand';
import { RedactReplyServiceService } from '../service/redact-reply-service.service';
 import { AppError } from '../common/class/app-error';
// import { of, Observable } from 'rxjs';
// import { map, catchError } from 'rxjs/operators';
import { SubSink } from '../../../node_modules/subsink';
import {  HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IRedact,IRedactReply,Redact,RedactReply,IRedactHF } from '../class/redact';
import { Router ,ActivatedRoute} from '@angular/router';
import { timer, Subscription } from "rxjs";
@Component({
  selector: 'app-sendertext-view',
  templateUrl: './sendertext-view.component.html',
  styleUrls: ['./sendertext-view.component.scss']
})
export class SendertextViewComponent implements OnInit {

  destShortURL:string;
  public redact= new Redact;
  private subs = new SubSink();
  showLogo:boolean=true;
   constructor(private user: RedactReplyServiceService,private http: HttpClient,private router:Router, private route: ActivatedRoute,private translate: TranslateService) {
    this.route.params.subscribe( params => this.destShortURL=(params.shortURL));
     }
  ngOnInit() {
       this.getRedactSenderTextView();
   }

   getRedactSenderTextView() {
    this.subs.sink = this.user.getRedactSenderTextView(this.destShortURL)
   .subscribe(resp => {
       if (!(resp instanceof AppError)) {      
         
         const response = resp as IRedactHF;
          const result = response.ResultContent;
          this.redact=result;
         if( this.redact.LogoSrc==null)
        {
         this.showLogo=false;
        }
        this.translate.use(this.redact.Language);
       localStorage.setItem('Langauge', this.redact.Language);
     }
   });
 
  }
 

}
