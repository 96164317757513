export class AutoLockApiResponse {
    status: string;
    statusCode: number;
    statusText: string;
    success: boolean;
    message: string;
    resultContent: ResultContent;
  }
  
  export class ResultContent {
    enabled: boolean | null;
    riskLevel: string;
    lock: boolean | null;
    city: string;
    region: string;
    regionCode: string;
    countryCode: string;
    countryName: string;
    continentCode: string;
    network: string;
  }
  
  