export class DisappearingInk {
  TimeAllowed: number;
  OpenUrl:string;
  ViewsLeft: string;
  DisplayViewsLeft:string;
  IsDisappearingInkExpired:boolean;
  AllowTextCopy: boolean;
  ConfidentialityStatement:string;
  LogId:string;
  LogoSrc: string;
  Language:string;
  DisappearingInkCustomText:string;
  DisappearingInkText:string;
}
export interface IDisappearingInk {
    TimeAllowed: number;
    OpenUrl:string;
    ViewsLeft: string;
    DisplayViewsLeft:string;
    IsDisappearingInkExpired:boolean;
    AllowTextCopy: boolean;
    ConfidentialityStatement:string;
    LogId:string;
    LogoSrc: string;
    Language:string;
    DisappearingInkCustomText:string;
    DisappearingInkText:string;
  }
  

export interface IDisappearingInkHF {
  Status: string;
  StatusCode: string;
  StatusText: string;
  Message: any;
  ResultContent: IDisappearingInk;
}
