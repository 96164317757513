export class Redact {
    TimeAllowed: number;
    OpenUrl:string;
    ViewsLeft: string;
    DisplayViewsLeft:string;
    IsRedactExpired:boolean;
    AllowTextCopy: boolean;
    LogoSrc: string;
    ConfidentialityStatement:string;
    LogId:string;
    Language:string;
    resultContents: RedactReply;
}
export interface IRedact {
    TimeAllowed: number;
    OpenUrl:string;
    ViewsLeft: string;
    DisplayViewsLeft:string;
    IsRedactExpired:boolean;
    AllowTextCopy: boolean;
    ConfidentialityStatement:string;
    LogId:string;
    LogoSrc: string;
    Language:string;
    resultContents: IRedactReply;
  }
  export interface IRedactReply {
    Text: string;
    HtmlText: string;
    Amount: string;
    SeqIndex:number;
}
export class RedactReply {
  Text: string;
  HtmlText: string;
  Amount: string;
  SeqIndex:number;
}
export interface IRedactHF {
  Status: string;
  StatusCode: string;
  StatusText: string;
  Message: any;
  ResultContent: IRedact;
}
export interface IEmailVefifyHF {
  Status: string;
  StatusCode: string;
  StatusText: string;
  Message: any;
  ResultContent: IEmailVefify;
}
export interface IEmailVefify {
  Verified: string;

}
export class EmailVefify {
  Verified: string;

}
export interface IBrandDetailsHF {
  Status: string;
  StatusCode: string;
  StatusText: string;
  Message: any;
  ResultContent: IBrandDetails;
}
export interface IBrandDetails {
  Language: string;
  LogoSrc:string;
  EMailDispatchOption: number;
}
export class BrandDetails {
  Language: string;
  LogoSrc:string;
  EMailDispatchOption:number;

}
export class CountryCodeDetails {
  CountryCode: string;
  CountryName:string;
  DialCode:string;

}
export interface ICountryCodeDetails {
  CountryCode: string;
  CountryName:string;
  DialCode:string;
  
}
  
export interface ICountryDialCodeForSenderAddress{
  DialCode: string;
}
