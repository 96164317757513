import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{TextViewComponent}from '../app/text-view/text-view.component';
import{TimeRemainingComponent}from '../app/time-remaining/time-remaining.component';
import{DITimeRemainingComponent}from '../app/di-time-remaining/di-time-remaining.component';
import{SendertextViewComponent}from '../app/sendertext-view/sendertext-view.component';
import{ViewsRemainingComponent}from '../app/views-remaining/views-remaining.component';
import{DIViewsRemainingComponent}from '../app/di-views-remaining/di-views-remaining.component';
import{EncryptDisappearInkComponent} from'../app/encrypt-disappear-ink/encrypt-disappear-ink.component';
import{DisappearInkComponent} from'../app/disappear-ink/disappear-ink.component';
import{RedactNewComponent} from '../app/redact-new/redact-new.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { AutoLockAccessDeniedComponent } from './auto-lock-access-denied/auto-lock-access-denied.component';

//import { AppPreloadSelectStrategy } from './app-preload-select-strategy';

const routes: Routes = [];

@NgModule({ 
  imports: [
    RouterModule.forRoot([
         { path: 'ViewText/:shortURL', component: TextViewComponent },
        { path: 'timeRemaining', component: TimeRemainingComponent },
        { path: 'viewRemaining', component: ViewsRemainingComponent },
        { path: 'DItimeRemaining', component: DITimeRemainingComponent },
        { path: 'DIviewRemaining', component: DIViewsRemainingComponent },
        { path: 'SenderViewText/:shortURL', component: SendertextViewComponent },
        { path: 'DI/secureviewtext/:shortURL/:language', component: EncryptDisappearInkComponent },
        { path: 'DI/ViewText/:shortURL', component: DisappearInkComponent },
        { path: 'encryptdisappearink/:shortURL/:language', component: EncryptDisappearInkComponent },
        { path: 'ediink/:shortURL/:language', component: EncryptDisappearInkComponent },
        { path: 'disappearink/:shortURL', component: DisappearInkComponent },
        { path: 'diink/:shortURL', component: DisappearInkComponent },
        { path: 'Redact', component: RedactNewComponent },
        { path: 'emailverification', component: EmailVerificationComponent},
        { path: 'accessdenied/:shortURL/:viewType', component: AutoLockAccessDeniedComponent},

        
       // { path: 'DisappearInk/:shortURL', component: DisappearInkComponent },
          // { path: '', redirectTo: 'home', pathMatch: 'full',data : {email : 'mushamsudha@gmail.com'} },
      
    ], { enableTracing: false})
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
