import { Component, OnInit } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DisappearingInk } from '../class/DisappearingInk';
import { IRedact,IRedactReply,Redact,RedactReply,IRedactHF } from '../class/redact';
@Component({
  selector: 'app-time-remaining',
  templateUrl: './time-remaining.component.html',
  styleUrls: ['./time-remaining.component.scss']
})
export class TimeRemainingComponent implements OnInit {

  constructor(private translate: TranslateService) { }
  public redact= new Redact;
  public viewsLeft:string;
  public DI= new DisappearingInk;
  showLogo:boolean=true;
  isDkUrl:boolean=false;
  isDI:boolean=false;
  companyLogoURL:string="";
  ngOnInit() {
    if(localStorage.getItem('Langauge').toString()=='da-dk')
    {
this.isDkUrl=true;
    }
    
     this.translate.use(localStorage.getItem('Langauge'));
     this.redact=this.getRedactDetails();
     if( this.redact!=null){
       this.viewsLeft=this.redact.DisplayViewsLeft;
     if( this.redact.LogoSrc==null ||this.redact.LogoSrc=="")
     {
             this.showLogo=false;
     }
     else
     {
      this.showLogo=true;
      this.companyLogoURL=this.redact.LogoSrc;
     }
    }
    //  this.DI=this.getDIDetails();
    //  if( this.DI!=null){
    //   this.viewsLeft=this.DI.DisplayViewsLeft;
    //   this.isDI=true;
    //  if( this.DI.LogoSrc==null ||this.DI.LogoSrc=="")
    //  {
    //          this.showLogo=false;
    //  }
    //  else
    //  {
    //        this.showLogo=true;
    //   this.companyLogoURL=this.DI.LogoSrc;
    //  }
    // }
   // localStorage.clear();
  }
  getRedactDetails() {
    const key = 'redact';
    if (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) {
      return JSON.parse(localStorage.getItem(key));
    }  else {
      return null;
    }
  }
  // getDIDetails() {
  //   const key = 'disapperInk';
  //   if (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) {
  //     return JSON.parse(localStorage.getItem(key));
  //   }  else {
  //     return null;
  //   }
  // }

  
}
