import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  FooterHtml: SafeHtml;
  // confidentialityStatement :string;
  // @Input() ConfidentialityStmt :string;

  @Input() OpenUrl: string;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {



    this.FooterHtml = "<p>RPost Copyright 2000 - 2024 All Rights Reserved.\r\n        <a href=\"https://www.rpost.com/legal-notice\" title=\"Legal Notice\" target=\"_blank\" >Legal Notice</a>\r\n        - View general terms &amp; conditions, service level agreements, privacy and legal notices. RPost does not provide legal advice.</p>\r\n    <p>RPost technology is <a href=\"https://www.rpost.com/about-rpost/intellectual-property/patents\" title=\"RPost Patents\" target=\"_blank\">patented</a>\r\n        with worldwide coverage. REGISTERED EMAIL<sup>®</sup>, RPOST<sup>®</sup>, RMAIL<sup>®</sup>, (R)<sup>®</sup>, (R)EGISTERED EMAIL<sup>®</sup>, LEGAL PROOF<sup>®</sup>, ESIGNOFF<sup>®</sup>, SIDENOTE<sup>®</sup>, SECURMAIL(TM) are among RPost’s\r\n        <a href=\"https://www.rpost.com/about-rpost/intellectual-property/trademarks\" target=\"_blank\">registered trademarks</a>\r\n        in the United States and registered and unregistered trademarks in countries worldwide.</p>";

  }
  ngOnChanges() {

    //   if(this.ConfidentialityStmt== undefined || this.ConfidentialityStmt== "" || this.ConfidentialityStmt== null)
    //   {
    //     this.FooterHtml ="<p>RPost Copyright 2000 - 2020 All Rights Reserved.\r\n        <a href=\"https://www.rpost.com/legal-notice\" title=\"Legal Notice\" target=\"_blank\">Legal Notice</a>\r\n        - View general terms &amp; conditions, service level agreements, privacy and legal notices. RPost does not provide legal advice.</p>\r\n    <p>RPost technology is <a href=\"https://www.rpost.com/about-rpost/intellectual-property/patents\" title=\"RPost Patents\" target=\"_blank\">patented</a>\r\n        with worldwide coverage. REGISTERED EMAIL®, RPOST®, RMAIL®, (R)®, (R)EGISTERED EMAIL®, LEGAL PROOF®, ESIGNOFF®, SIDENOTE®, SECURMAIL(TM) are among RPost’s\r\n        <a href=\"https://www.rpost.com/about-rpost/intellectual-property/trademarks\" target=\"_blank\">registered trademarks</a>\r\n        in the United States and registered and unregistered trademarks in countries worldwide.</p>";

    //      }
    //      else
    //      {
    //        this.FooterHtml=this.ConfidentialityStmt;
    //      }
  }

//   footerText(text:any){
//     console.log('footerText',text );
//     const modifiedText = text
//     .replace(/®/g, '<sup>®</sup>')
//     .replace(/™/g, '<sup>™</sup>');

// console.log('footerText', modifiedText);
// //document.body.innerHTML += `<footer>${modifiedText}</footer>`;
// return modifiedText;
//   }
footerText(text: string): string {
  return text
    .replace(/®/g, '<sup>®</sup>')
    .replace(/™/g, '<sup>™</sup>');
}
}
