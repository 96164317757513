export class DestinationDetails {
    MessageId : string;
    DestinationId : number;
    TimeOpened : Date;
    DispatchTime : Date;
    DestinationAddress : string;
    MessageLanguage : string;
    MessageSubject : string;
    MessageCategory : number;
    SenderAddress : string;
    ShortUrl : string;
    MessageCreatedDate : Date;
    TimeZoneOffset : number;
    MessageType : number;
    EMailDispatchOption : number;
    PhoneNumber : string;
    DestinationName : string;
}