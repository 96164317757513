import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { IRedact,IRedactReply,Redact,RedactReply,IRedactHF } from '../class/redact';
import { DisappearingInk } from '../class/DisappearingInk';

@Component({
  selector: 'app-views-remaining',
  templateUrl: './views-remaining.component.html',
  styleUrls: ['./views-remaining.component.scss']
})
export class ViewsRemainingComponent implements OnInit {
  public redact= new Redact;
  showLogo:boolean=true;
  isDI:boolean=false;
  isDkUrl:boolean=false;
  public DI= new DisappearingInk;
  companyLogoURL:string="";
  constructor(private translate: TranslateService) { }

  ngOnInit() {
    if(localStorage.getItem('Langauge').toString()=='da-dk')
    {
this.isDkUrl=true;
    }
    this.translate.use(localStorage.getItem('Langauge').toString()); 
    this.redact=this.getRedactDetails();
    if(this.redact !=null){
    if( this.redact.LogoSrc==null ||this.redact.LogoSrc=="")
    {
            this.showLogo=false;
    }
    else
    {
     this.showLogo=true;
     this.companyLogoURL=this.redact.LogoSrc;
    }
  }
  //   this.DI=this.getDIDetails();
  //  if( this.DI!=null){
  //     this.isDI=true;
  //    if( this.DI.LogoSrc==null ||this.DI.LogoSrc=="")
  //   {
  //           this.showLogo=false;
  //   }
  //   else
  //   {
    
  //    this.showLogo=true;
  //    this.companyLogoURL=this.DI.LogoSrc;
  //   }
  //  }
 //  localStorage.clear();
 }
 getRedactDetails() {
   const key = 'redact';
   if (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) {
     return JSON.parse(localStorage.getItem(key));
   }  else {
     return null;
   }
 }
//  getDIDetails() {
//   const key = 'disapperInk';
//   if (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) {
//     return JSON.parse(localStorage.getItem(key));
//   }  else {
//     return null;
//   }
// }

}
