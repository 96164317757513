import { Component, Input, OnInit,InputDecorator } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IBrand,IBrandHF} from '../common/ibrand';
 import { AppError } from '../common/class/app-error';
// import { of, Observable } from 'rxjs';
// import { map, catchError } from 'rxjs/operators';
import { SubSink } from '../../../node_modules/subsink';
import {  HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  hideElement = false;
 @Input() showCompanyLogo :boolean;
 @Input() isDisappearInk :boolean;
 @Input() companyLogoURL :string;
  HeaderHtml: SafeHtml;
  public brand;
  private subs = new SubSink();
  constructor(private http: HttpClient,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.use(localStorage.getItem('Langauge'));
  }
    ngOnChanges()
    {
    this.translate.use(localStorage.getItem('Langauge'));
   }
 
}
