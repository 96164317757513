import { Component, OnInit } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { switchMap, catchError, map } from 'rxjs/operators';
import { interval } from 'rxjs';
import { IBrand, IBrandHF } from '../common/ibrand';
import { RedactReplyServiceService } from '../service/redact-reply-service.service';
import { AppError } from '../common/class/app-error';
// import { of, Observable } from 'rxjs';
// import { map, catchError } from 'rxjs/operators';
import { SubSink } from '../../../node_modules/subsink';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IDisappearingInkHF, IDisappearingInk, DisappearingInk } from '../class/DisappearingInk';
import { IPostResponseHF, IPostResponse, PostResponse } from '../class/PostResponse';
import { Router, ActivatedRoute } from '@angular/router';
import { timer, Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { stringify } from 'querystring';
import{IEmailVefifyHF,IEmailVefify,EmailVefify,IBrandDetailsHF,IBrandDetails,BrandDetails, CountryCodeDetails, ICountryCodeDetails, ICountryDialCodeForSenderAddress} from '../class/redact';
import { DestinationDetails } from '../class/DestinationDetails';
import { ResultContent } from '../class/AutoLockApiResponse';
import { AI_AutoLock_Access_Check } from '../service/AutoLock_Access_Check/AI_AutoLock_Access_Check';
import { AutoLockRequestModel } from '../class/AutoLockRequestModel';
import { SendAccessControlNoticeRequestModel } from '../class/SendAccessControlNoticeRequestModel';
import { Location } from '@angular/common';
//import { debug } from 'console';

@Component({
  selector: 'app-encrypt-disappear-ink',
  templateUrl: './encrypt-disappear-ink.component.html',
  styleUrls: ['./encrypt-disappear-ink.component.scss']
})
export class EncryptDisappearInkComponent implements OnInit {
  // enableEdit: boolean = true;
  mySubscription: Subscription;
  logoId: string;
  public brandDetails=new BrandDetails;
  emailDispatchOption: number;
  isSMSOptionEnabled: boolean = false;
  DialCodeForSenderAddress: string;
  selectedLanguage: string;
  hideElement = false;
  destShortURL: string;
  HeaderHtml: SafeHtml;
  private subs = new SubSink();
  public copied = false;
  showLogo: boolean = false;
  companyLogoURL: string = "";
  countDown: Subscription;
  confidentialityStatement: string;
  counter: number;
  tick = 1000;
  subscription: Subscription;
  loading = false;
  submitted = false;
  loginForm: FormGroup;
  PasswordForm: FormGroup;
  invalidEmail: boolean;
  invalidPassword: boolean;
  sentPassword: boolean;
  isAutoLock_AccessDenied: boolean;
  accessDeniedText: string;
  isDisappearInk: boolean;
  contactSenderText: string;
  constructor(private formBuilder: FormBuilder, private user: RedactReplyServiceService, private AI_Autolock_user: AI_AutoLock_Access_Check, private http: HttpClient, private router: Router, private route: ActivatedRoute, private translate: TranslateService, private location: Location) {
    this.route.params.subscribe(params => {
      this.destShortURL = (params.shortURL);
      //  this.translate.use(params.language.toString());
      this.translate.use(params.language.toString());
      //  alert(1);
      //  alert(params.language.toString());
      localStorage.setItem('Langauge', params.language);
    });
  }
  public verifyEmail: boolean = false;
  public verifyPassword: boolean = false;
  public verifyUser: boolean = false;
  public conditionvalue: number = 0;
  public DI = new DisappearingInk;
  //emailPattern="^(?!\\.)(?!\\')(?!\\^)(?!\\?)(?!\\])(?!\\*)(?!\\{)(?!\\})(?!\\[)(?!.*?[.]{2})|[#!_%&$^+-][a-z0-9.!#_%'&^$?!.\]*{}\[+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
  //emailPattern="^(?!.*?[.]{2})|[a-z0-9.!#_%'&^$?!.\]/*{}\[|/~=+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
  emailPattern = "^[a-zA-Z0-9.!#_%'&^$?\\-/+=]*[a-zA-Z0-9.!#_%'&^$?\\-/+=]+@[a-zA-Z\\u0080-\\uFFFF0-9_-]+\\.[a-zA-Z]{2,4}$";

  ngOnInit() {
    this.getBrandingDetails();
    // this.translate.use(localStorage.getItem('Langauge'));
    // Initialize the destination details and result content objects
    var destDetails = new DestinationDetails();
    var resultContent = new ResultContent();

    // Detect client information
    this.detectClientInfo().then(clientInfo => {
      const { ipAddress, userAgent, referer, language } = clientInfo;
      console.log('Client Info:', ipAddress, userAgent, referer, language);

      // Get destination details independently
      this.AI_Autolock_user.getDestinationDetails(this.destShortURL).subscribe(
        destinationDetails => {
          if (destinationDetails) {
            destDetails.MessageId = destinationDetails.MessageId;
            destDetails.DestinationAddress = destinationDetails.DestinationAddress;
            destDetails.SenderAddress = destinationDetails.SenderAddress;
            destDetails.MessageLanguage = destinationDetails.MessageLanguage;
            destDetails.MessageSubject = destinationDetails.MessageSubject;
            destDetails.MessageCreatedDate = destinationDetails.MessageCreatedDate;

            // Prepare auto lock request model
            const autoLockRequest: AutoLockRequestModel = {
              IpAddress: ipAddress,
              SenderAddress: destDetails.SenderAddress,
              RecipientAddress: destDetails.DestinationAddress,
              ProductLine: "RMail",
              ProductFeature: "DisappearingInk",
              ReferenceId: destDetails.MessageId,
              Http_UserAgent: userAgent,
              HTTP_Referer: referer,
              Browser_Language: language,
              RecipientType: 0,
              RecipientMobile: null
            };

            // Call auto lock access check
            this.AI_Autolock_user.getautoLockAccess(autoLockRequest).subscribe(
              autoLockResponse => {
                if (autoLockResponse && autoLockResponse.resultContent) {
                  resultContent = autoLockResponse.resultContent;

                  if (resultContent.lock) {
                    // Prepare access control notice request model
                    const accessControlNoticeRequest: SendAccessControlNoticeRequestModel = {
                      SenderAddress: destDetails.SenderAddress,
                      RecipientAddress: destDetails.DestinationAddress,
                      MessageId: destDetails.MessageId,
                      Location: `${resultContent.city}, ${resultContent.regionCode}`,
                      Country: resultContent.countryName,
                      Network: resultContent.network,
                      Feature: "RMail - Disappearing Ink",
                      OriginalSentDate: destDetails.MessageCreatedDate,
                      IpAddress: ipAddress,
                      ContinentCode: resultContent.continentCode,
                      AlertType: resultContent.riskLevel === 'Y' ? 'Yellow' : 'Red',
                      Language: destDetails.MessageLanguage,
                      Subject: destDetails.MessageSubject
                    };

                    // Send access control notice
                    this.AI_Autolock_user.sendAccessControlNotice(accessControlNoticeRequest).subscribe(
                      response => {
                        console.log('Access Control Notice Sent:', response);
                        this.isAutoLock_AccessDenied = true;
                        this.accessDeniedText = "Access Denied";
                        this.contactSenderText = "Please contact the sender for further information.";
                        this.isDisappearInk = true;
                        //this.router.navigate(['accessdenied']);
                        //this.router.navigateByUrl('/accessdenied', { skipLocationChange: false });

                        //const queryString = `?destShortURL=${encodeURIComponent(this.destShortURL)}&ViewType=DI`;
                        //this.location.go('/accessdenied');
                        //this.location.go(`/accessdenied/${this.destShortURL}`+"/DI");
                        this.router.navigateByUrl(`/accessdenied/${this.destShortURL}` + "/DI");
                        return;
                      },
                      error => {
                        console.error('Error sending access control notice:', error);
                      }
                    );
                  } else {
                    console.log('Access is allowed');
                    this.verifyEmail = true;
                    this.PasswordForm = this.formBuilder.group({
                      password: ['', Validators.required]
                    });
                    this.loginForm = this.formBuilder.group({
                      // emailId: ['', Validators.required] 
                      //emailId:  [null, [Validators.required, Validators.pattern(this.emailPattern), Validators.email]]
                      emailId: [null, [Validators.required, Validators.pattern(this.emailPattern)]]

                    });
                  }
                  if (!resultContent.enabled) {
                    console.log('Autolock feature is not enabled');
                    this.verifyEmail = true;
                    this.PasswordForm = this.formBuilder.group({
                      password: ['', Validators.required]
                    });
                    this.loginForm = this.formBuilder.group({
                      // emailId: ['', Validators.required] 
                      //emailId:  [null, [Validators.required, Validators.pattern(this.emailPattern), Validators.email]]
                      emailId: [null, [Validators.required, Validators.pattern(this.emailPattern)]]

                    });
                  }
                }
                else {
                  this.verifyEmail = true;
                  this.PasswordForm = this.formBuilder.group({
                    password: ['', Validators.required]
                  });
                  this.loginForm = this.formBuilder.group({
                    // emailId: ['', Validators.required] 
                    //emailId:  [null, [Validators.required, Validators.pattern(this.emailPattern), Validators.email]]
                    emailId: [null, [Validators.required, Validators.pattern(this.emailPattern)]]
                  });
                }
              },
              error => {
                console.error('Error during auto lock access check:', error);
              }
            );
          }
          else {
            this.verifyEmail = true;
            this.PasswordForm = this.formBuilder.group({
              password: ['', Validators.required]
            });
            this.loginForm = this.formBuilder.group({
              // emailId: ['', Validators.required] 
              //emailId:  [null, [Validators.required, Validators.pattern(this.emailPattern), Validators.email]]
              emailId: [null, [Validators.required, Validators.pattern(this.emailPattern)]]
            });
          }
        },
        error => {
          console.error('Error getting destination details:', error);
        }
      );
    });
    //    this.getRedactTextView();
    //     this.countDown = timer(0, this.tick)
    //    .subscribe(() => --this.redact.TimeAllowed)
    //    this.mySubscription= interval(60000).subscribe((x =>{
    //      this.subs.sink = this.user.apiRedactTextViewDuration(this.logoId)
    //      .subscribe(resp => {
    //      });
    //  }));

    // this.sub = Observable.interval(10000)
    // .subscribe((val) => { console.log('called'); });

  }
  getDITextView(password: string) {
    this.subs.sink = this.user.postDITextView(this.destShortURL, password)
      // this.subs.sink = this.user.getDITextView(this.destShortURL)
      .subscribe(resp => {

        if (!(resp instanceof AppError)) {

          const response = resp as IDisappearingInkHF;
          const result = response.ResultContent;
          this.DI = result;
          // localStorage.setItem('redact', null);
          localStorage.setItem('disapperInk', JSON.stringify(this.DI));

          if (this.DI.LogoSrc == null || this.DI.LogoSrc == "") {
            this.showLogo = false;
          }
          else {
            this.showLogo = true;
            this.companyLogoURL = this.DI.LogoSrc;
          }
          this.confidentialityStatement = this.DI.ConfidentialityStatement;
          const source = interval(this.DI.TimeAllowed * 1000);
          this.subscription = source.subscribe(val => this.redirectToTimeRemaining());

          let count: number = 1;
          if (this.DI.DisappearingInkText != null) {
            // this.copyText=this.copyText.concat( this.DI.DisappearingInkCustomText ); 
            // this.copyText=this.copyText.concat(" :");
            //    this.copyText=this.copyText.concat('\n');
            //   this.copyText=this.copyText.concat(this.DI.DisappearingInkText);
            this.copyText = this.copyText.concat(this.DI.DisappearingInkText.toString().replace("<br/>", "\n"));
            this.copyText = this.copyText.concat('\n');
          }

          this.logoId = this.DI.LogId;
          if ((this.DI.ViewsLeft == "0") || (this.DI.IsDisappearingInkExpired == true)) {
            this.router.navigate(['DIviewRemaining'], { skipLocationChange: true });
          }
        }
      });

  }

  // async detectClientInfo(): Promise<{ ipAddress: string, userAgent: string, referer: string, language: string }> {
  //   const ipAddress = await this.getIpAddress();//"183.82.7.117";//
  //   const userAgent = navigator.userAgent;
  //   const referer = document.referrer;
  //   const language = navigator.language || (navigator as any).userLanguage;

  //   return { ipAddress, userAgent, referer, language };
  // }

  // getIpAddress(): Promise<string> {
  //   return this.http.get<{ ip: string }>('https://api64.ipify.org?format=json').pipe(
  //     map(response => response.ip)
  //   ).toPromise();
  // }

  async detectClientInfo(): Promise<{ ipAddress: string, userAgent: string, referer: string, language: string }> {
    const serverVariables = await this.AI_Autolock_user.getServerVariables().toPromise();

    if (serverVariables) {
      const { IPAddress, UserAgent, Referer, BrowserLanguage } = serverVariables;
      return {
        ipAddress: IPAddress || null,
        userAgent: UserAgent || null,
        referer: Referer || null,
        language: BrowserLanguage || null
      };
    } else {
      return {
        ipAddress: null,
        userAgent: null,
        referer: null,
        language: null
      };
    }
  }


  // get f() { return this.loginForm.controls; }
  get g() { return this.PasswordForm.controls; }
  redirectToTimeRemaining() {
    this.router.navigate(['DItimeRemaining'], { skipLocationChange: true });
  }
  public copyText: string = "";
  emailId: string;
  get f() { return this.loginForm.controls; }
  SendPassword() {
    if (this.loginForm.invalid) {
      this.invalidEmail = true;
      return;
    }
    this.user.postapiVerifyEmail(this.destShortURL, this.loginForm.value.emailId)
      .subscribe(resp => {
        const response = resp as IPostResponseHF;
        const result = response.ResultContent;
        let postResponse = result;
        if (response.Status == "Success" && postResponse.Verified.toString() == "true") {
          this.verifyEmail = false;
          this.verifyPassword = true;
          this.verifyUser = false;
          this.loading = true;
          this.invalidEmail = false;
          this.sentPassword = true;
        }
        else {
          this.invalidEmail = true;
          return;
        }
      })
  }
  VerifyPassword() {
    if (this.PasswordForm.invalid) {
      return;
    }
    this.user.postapiVerifyPassword(this.destShortURL, this.PasswordForm.value.password)
      .subscribe(resp => {
        const response = resp as IPostResponseHF;
        const result = response.ResultContent;
        let postResponse = result;
        if (response.Status == "Success" && postResponse.Verified.toString() == "true") {
          this.verifyEmail = false;
          this.verifyPassword = false;
          this.invalidPassword = false;
          this.sentPassword = false;
          this.verifyUser = true;
          this.submitted = true;
          this.getDITextView(this.PasswordForm.value.password);


          this.countDown = timer(0, this.tick)
            .subscribe(() => --this.DI.TimeAllowed)
          this.mySubscription = interval(60000).subscribe((x => {
            if (this.DI.TimeAllowed > 0) {
              if ((this.DI.ViewsLeft != "0")) {
                this.subs.sink = this.user.apiDITextViewDuration(this.logoId)
                  .subscribe(resp => {
                  });
              }
            }
          }));

        }
        else {
          this.invalidPassword = true;
          this.sentPassword = false;
          return;
        }
      })
  }
  copyMessage() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.copyText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
    setTimeout(() => {                           //<<<---using ()=> syntax
      this.copied = false;
    }, 3000);
  }
  getBrandingDetails()
{
 this.subs.sink = this.user.postUserInformation(this.destShortURL,"DI")
  .subscribe(resp => {
           if (!(resp instanceof AppError)) {      
        const response = resp as IBrandDetailsHF;
         const result = response.ResultContent;
         this.brandDetails=result;
                this.translate.use(this.brandDetails.Language);
         localStorage.setItem('Langauge', this.brandDetails.Language);
         this.emailDispatchOption = this.brandDetails.EMailDispatchOption;
         this.isSMSOptionEnabled = this.emailDispatchOption == 2 ? true : false;
         if (this.isSMSOptionEnabled == true) 
           {
            this.subs.sink = this.user.postCountryDialCodeForSenderAddress(this.destShortURL)
            .subscribe(resp => {
              if(!(resp instanceof AppError)){
                const response = resp as ICountryDialCodeForSenderAddress;
                this.DialCodeForSenderAddress = response.DialCode;
              }
            })
           }
         if( this.brandDetails.LogoSrc==null ||this.brandDetails.LogoSrc=="")
         {
                 this.showLogo=false;
         }
         else
         {
          this.showLogo=true;
          this.companyLogoURL=this.brandDetails.LogoSrc;
         }}});
}

}