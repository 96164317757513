import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  baseApiUrl = environment.baseUrl;
  baseApiKey = environment.apiKey;
  actAPIUrl = environment.ActAPIbaseUrl;
   constructor() { }
  
}
