import { Component, OnInit } from '@angular/core';
import { BrandDetails, IBrandDetailsHF } from '../class/redact';
import { SubSink } from 'subsink';
import { RedactReplyServiceService } from '../service/redact-reply-service.service';
import { AppError } from '../common/class/app-error';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-auto-lock-access-denied',
  templateUrl: './auto-lock-access-denied.component.html',
  styleUrls: ['./auto-lock-access-denied.component.scss']
})
export class AutoLockAccessDeniedComponent implements OnInit {
  logoId:string;
  public brandDetails=new BrandDetails;
  showLogo:boolean=true;
  companyLogoURL:string="";
  private subs = new SubSink();
  destShortURL:string;
  accessDeniedText : string;
  viewType : string;
  isDisappearInk : boolean;
  showCompanyLogo: boolean;
  contactSenderText: string;
  constructor(private user: RedactReplyServiceService, private http: HttpClient,private router:Router, private route: ActivatedRoute,private translate: TranslateService) {
   //alert("Constructor alert");
    this.route.params.subscribe(params => {
      this.destShortURL = params.shortURL;
      this.viewType = params.viewType.toString();
    });
  }

  ngOnInit() {
    this.getBrandingDetails();
  }

  getBrandingDetails()
  {
   this.subs.sink = this.user.postUserInformation(this.destShortURL,this.viewType)
    .subscribe(resp => {
             if (!(resp instanceof AppError)) {      
          const response = resp as IBrandDetailsHF;
           const result = response.ResultContent;
           this.brandDetails=result;
           this.accessDeniedText = "Access Denied";
           this.contactSenderText = "Please contact the sender for further information.";
           if(this.viewType == "RD")
           {
              this.isDisappearInk = false;
           }
           else if(this.viewType == "DI")
           {
              this.isDisappearInk = true;
           }
           this.translate.use(this.brandDetails.Language);
           localStorage.setItem('Langauge', this.brandDetails.Language);
           if( this.brandDetails.LogoSrc==null ||this.brandDetails.LogoSrc=="")
           {
                   this.showLogo=false;
                   this.showCompanyLogo = false;
           }
           else
           {
            this.showLogo=true;
            this.showCompanyLogo = true;
            this.companyLogoURL=this.brandDetails.LogoSrc;
           }}});
  }

}
