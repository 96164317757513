import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import{HeaderComponent}from './header/header.component';
import { HttpClient,HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './app-config.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewlinePipe } from './common/pipes/newline.pipe';
import { FooterComponent } from './footer/footer.component';
import { TimerPipe } from './common/pipes/timer.pipe';
import { TextViewComponent } from './text-view/text-view.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SendertextViewComponent } from './sendertext-view/sendertext-view.component';
import { TimeRemainingComponent } from './time-remaining/time-remaining.component';
import { ViewsRemainingComponent } from './views-remaining/views-remaining.component';
import { DisappearInkComponent } from './disappear-ink/disappear-ink.component';
import{EncryptDisappearInkComponent} from './encrypt-disappear-ink/encrypt-disappear-ink.component';
import { SafeHtmlPipe } from './common/pipes/safe-html.pipe';
import { RedactNewComponent } from './redact-new/redact-new.component';
import { DITimeRemainingComponent } from './di-time-remaining/di-time-remaining.component';
import { DIViewsRemainingComponent } from './di-views-remaining/di-views-remaining.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { AutoLockAccessDeniedComponent } from './auto-lock-access-denied/auto-lock-access-denied.component'
export function HttpLoaderFactory(http: HttpClient) {
return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
 
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NewlinePipe,
    FooterComponent,
    TimerPipe,
   TextViewComponent,
    SendertextViewComponent,
    TimeRemainingComponent,
    ViewsRemainingComponent,
    DisappearInkComponent,
    EncryptDisappearInkComponent,
    SafeHtmlPipe,
    RedactNewComponent,
    DITimeRemainingComponent,
    DIViewsRemainingComponent,
    EmailVerificationComponent,
    AutoLockAccessDeniedComponent
    // HttpClientModule,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [AppConfigService
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
